import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
 
 render() {
   return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="row">
              <div className="col-sm-6">
                <span>©2019 Jocalis</span>
              </div>
              <div className="col-sm-6 align-right">
                <Link to="/contact" title="contact">Contact</Link> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )}
}

export default Footer
